import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';

import { colors, breakpoints } from 'styles/cp';

export interface Props {
  useRedesign?: boolean;
  companyLogoUrl: string;
}

const MobileCompanyLogo: React.FC<Props> = ({ useRedesign = false, companyLogoUrl }) => {
  const intl = useIntl();
  return !companyLogoUrl ? (
    <Fragment />
  ) : (
    <Fragment>
      <div className="mobile-logo-container">
        <div className="mobile-logo">
          <img
            className="company-logo"
            data-cy={'mobile_logo_target'}
            src={companyLogoUrl}
            alt={intl.formatMessage({ id: 'COMPANY_LOGO_ALT', defaultMessage: 'Company logo' })}
          />
        </div>
      </div>

      <style jsx>{`
        .mobile-logo-container {
          display: none;

          @media screen and (max-width: ${breakpoints.md}) {
            display: flex;
            justify-content: center;
            transform: ${useRedesign ? 'translateY(-55%)' : 'translateY(-90%)'};
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;

            .mobile-logo {
              display: flex;
              background-color: white;
              border: 1px solid ${colors.gray06};
              border-radius: 4px;
              padding: 10px;
              box-sizing: content-box;
              min-width: 40px;
              min-height: 20px;
              max-height: 40px;
              max-width: 120px;

              .company-logo {
                min-width: 40px;
                min-height: 20px;
                max-height: 40px;
                max-width: 120px;
              }
            }
          }
        }
      `}</style>
    </Fragment>
  );
};
export { MobileCompanyLogo };

export default MobileCompanyLogo;
