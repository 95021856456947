import * as React from 'react';

import Button from 'components/Shared/Button/Button';
import Hr from 'components/Shared/Hr/Hr';
import SegmentIO from 'reporting/SegmentIO';
import { getMonthlyPaymentReturn } from 'store/payment/helpers';
import { fontSize, breakpoints } from 'styles/cp';

export type Props = {
  amount: number;
  payEnabled: boolean;
  isBusiness: boolean;
  domainId: string;
  isUnplayable?: boolean;
};

const CKMonthlyExperiment: React.FC<Props> = ({
  amount,
  payEnabled,
  isBusiness,
  domainId = '',
  isUnplayable,
}: Props) => {
  const formattedAmount = amount?.toLocaleString('en-US');

  const timestamp = Date.now();
  const saleID = domainId.replace(/:|_/g, '-');
  const lbprid = `${saleID}-${timestamp}`;

  const onClickHandler = () => {
    SegmentIO.transactionEngaged({
      activity_type: 'pm_credit_karma',
      ui_action: 'engaged',
      ui_object: 'link',
      ui_object_detail: 'continue_to_credit_karma',
      ui_access_point: 'transaction_flow',
    });

    const redirectLinkEncoded = window.encodeURIComponent(
      `https://www.creditkarma.com/personal-loans/marketplace?referrer-partner=quickbooks&loan-amount=${amount}&loan-purpose=MajorPurchase&pay-enabled=${payEnabled}&b2c=${!isBusiness}`
    );
    const ckRedirectUrl = `https://www.creditkarma.com/u/auth/logon?s=partner_redirect&lbprid=${lbprid}&redirectUrl=${redirectLinkEncoded}`;
    window.open(ckRedirectUrl);
  };
  return (
    <React.Fragment>
      <style jsx>{`
        table {
          font-size: 12px;
        }
        .sd-w {
          font-size: ${fontSize.xs};
          text-align: left;
        }

        .header {
          font-size: ${fontSize.sm};
          font-family: 'AvenirNextforINTUIT-Bold';
          height: 78px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        table {
          width: 100%;
          display: flex;
          flex-direction: column;
        }

        tr {
          padding: 16px 0;
          display: flex;
          justify-content: space-between;
        }

        td {
          flex: 1;
        }

        .btn-w {
          margin: 20px 0;
          width: 100%;
        }

        .msg-bottom {
          text-align: center;
          font-size: 12px;
          line-height: 16.8px;
          @media screen and (max-width: ${breakpoints.md}) {
            margin-bottom: ${isUnplayable ? '16px' : '0'};
          }
        }
      `}</style>

      <div className="sd-w">
        <div className="header">Manage your ${formattedAmount} invoice with a personal loan</div>
        <table>
          <thead>
            <tr>
              <td>Loan amount</td>
              <td>Loan term</td>
              <td>APR</td>
              <td>Monthly payment</td>
            </tr>
          </thead>
          <Hr marginTop={0} marginBottom={0} />
          <tbody>
            <tr>
              <td>${formattedAmount}</td>
              <td>24 months</td>
              <td>8.49% - 17.99%</td>
              <td>
                ${getMonthlyPaymentReturn(amount, 8.49, 24)} - $
                {getMonthlyPaymentReturn(amount, 17.99, 24)}
                /mo
              </td>
            </tr>
            <Hr marginTop={0} marginBottom={0} />
            <tr>
              <td>${formattedAmount}</td>
              <td>36 months</td>
              <td>12.49% - 19.99%</td>
              <td>
                ${getMonthlyPaymentReturn(amount, 12.49, 36)} - $
                {getMonthlyPaymentReturn(amount, 19.99, 36)}/mo
              </td>
            </tr>
          </tbody>
        </table>
        <div className="btn-w">
          <Button
            onClick={onClickHandler}
            width="100%"
            height="50px"
            buttonType="primary"
            data-testid="check-approval-btn"
          >
            Check for offers
          </Button>
        </div>
        <div className="msg-bottom">
          Compare rates without hurting your credit scores. The above options are illustrative
          examples and personal loans are typically funded to your financial institution between 3-7
          business days after application approval. If approved, you will need to return to this
          invoice to apply those funds from your financial institution to your outstanding balance.
        </div>
      </div>
    </React.Fragment>
  );
};
export default CKMonthlyExperiment;
